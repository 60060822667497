import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ParametrosSistemaService {
  async verificarPermiso(codigo) {
    const parametro_sistema = await fetchWrapper.post(`${ruta}/parametros_sistema/verificar_permiso`, {codigo: codigo});
    return parametro_sistema;
  }
  async getParametrosSistema() {
    const parametros_sistema = await fetchWrapper.get(
      `${ruta}/parametros_sistema`
    );
    return parametros_sistema;
  }

}